/* @tailwind base; */
@tailwind components;
@tailwind utilities;

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  line-height: 1.25;
  word-break: break-word;
  /* font-family: Manrope, sans-serif; */
}

input[type='number'] {
  -moz-appearance: textfield;
}

body {
  /* display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
  min-height: 100%; */
}

li::marker {
  transform: scale(0.8);
}

#article-content h2,
#article-content h3 {
  margin-bottom: 5px;
}

#article-content ul {
  padding-left: 25px;
}
#article-content ul li {
  list-style-type: disc;
}

#article-content table {
  width: 100%;
  border-collapse: collapse;
  border-top: 1px solid #707070;
  border-left: 1px solid #707070;
}
#article-content table td {
  border-right: 1px solid #707070;
  border-bottom: 1px solid #707070;
  padding: 10px;
}

#__next {
  min-height: 100%;
  position: relative;
}

a {
  color: inherit;
  text-decoration: none;
}

body {
  /*移除移动端 cursor: pointer 时点击时有蓝色背景*/
  -webkit-tap-highlight-color: transparent;
}

/* 覆盖 input 自动填充 背景色 */
/*
input:-webkit-autofill {
  background-color: transparent !important;
  background-image: none !important;
  box-shadow: 0 0 0 1000px white inset !important;
}
*/

* {
  box-sizing: border-box;
}

img {
  vertical-align: middle;
}

.visually-hidden {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(0 0 99.9% 99.9%);
  overflow: hidden;
  height: 1px;
  width: 1px;
  padding: 0;
  border: 0;
}

.text-bold {
  font-family: Urbanist Bold !important;
  font-weight: bold;
}

.text-medium {
  font-family: Urbanist Medium !important;
}

.text-clamp-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 行数*/
  -webkit-box-orient: vertical;
}

.page-transition-enter {
  opacity: 0;
}
.page-transition-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.page-transition-exit {
  opacity: 1;
}
.page-transition-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.loading-indicator-appear,
.loading-indicator-enter {
  opacity: 0;
}
.loading-indicator-appear-active,
.loading-indicator-enter-active {
  opacity: 1;
  transition: opacity 2000ms;
}

.hasMarker li {
  padding-left: 30px;
  position: relative;
}
.hasMarker li::before {
  content: '·';
  position: absolute;
  font-size: 30px;
  left: 20px;
  /* top: -10px; */
  line-height: 15px;
}
/* bodyMedium: { xs: 13, md: 13, lg: 14, xl: 14 }, */
/* headlineSmall: { xs: 16, md: 18, lg: 20, xl: 20 }, */
/* titleMedium: { xs: 16, md: 16, lg: 18, xl: 18 }, */

.product-description {
  margin-bottom: 60px;
}
.product-description img {
  max-width: 100%;
  vertical-align: middle;
}

.sentry-error-embed {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: 0;
  transform: translate(-50%, -50%);
}

#article-content a {
  color: #0051cf;
}
#article-content img {
  width: 100%;
  max-width: 100%;
  vertical-align: middle;
}

#article-content p {
  line-height: 1.5;
  margin-bottom: 4px;
}

/* */
div.SnackbarContainer-top.SnackbarContainer-center {
  left: 0;
  right: 0;
  top: 26px;
  transform: none;
  max-width: none;
  align-items: center;
  z-index: 10001;
}
div.SnackbarContainer-top.SnackbarContainer-center > div {
  max-width: calc(100% - 40px);
}
div.SnackbarContainer-root .SnackbarItem-message {
  padding: 5px 0;
}
div.SnackbarContainer-root .SnackbarItem-contentRoot {
  border-radius: 10px;
}
div.SnackbarContainer-root .SnackbarItem-success {
  background: #edfaeb;
  box-shadow: 0 3px 2px 1px rgba(0, 33, 4, 0.05);
  color: #1bba1a;
}
div.SnackbarContainer-root .SnackbarItem-warning {
  background: #fefce6;
  box-shadow: 0 3px 6px 1px rgba(102, 58, 0, 0.05);
  color: #feb60d;
}
div.SnackbarContainer-root .SnackbarItem-error {
  background: #fff3ef;
  box-shadow: 0 3px 6px 1px rgba(69, 3, 10, 0.05);
  color: #dd3730;
}
div.SnackbarContainer-root .SnackbarItem-info {
  background: #e6f4ff;
  box-shadow: 0 3px 6px 1px rgba(0, 13, 55, 0.05);
  color: #0051cf;
}

/* dreo app 中隐藏*/
.isDreoApp .dreo-app-hide {
  display: none !important;
}

#hs-mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  background: #fff;
}
.hs-spinner {
  color: official;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
}
.hs-spinner div {
  transform-origin: 40px 40px;
  animation: hs-spinner 1.2s linear infinite;
}
.hs-spinner div:after {
  content: ' ';
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #0051cf;
}
.hs-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.hs-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.hs-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.hs-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.hs-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.hs-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.hs-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.hs-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.hs-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.hs-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.hs-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.hs-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes hs-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.product-faq-question {
  color: var(--Semantic-Color-Label-Label-Medium, #696969);
  font-size: 13px;
  font-weight: 400;
  line-height: 150%; /* 19.5px */
}

.product-faq-question ul {
  list-style: disc;
  padding-left: 20px;
}

.product-faq-question ol {
  list-style: decimal;
  padding-left: 20px;
}

@media (min-width: 992px) {
  .product-faq-question {
    font-size: 16px;
    line-height: 125%; /* 19.5px */
  }
}
